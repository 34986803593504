export const API_ROUTER_UTILS = {
  url: {
    auth: {
      tokens: 'tokens',
      signUp: 'users',
      confirmEmail: 'users/confirm-email',
      userRole: 'users/role',
      trialCreationFirst:"v1/accounts/trial-creaiton-first",
       trialCreationSecond:"v1/accounts/trial-creaiton-second"
    },
    home: {
      createVersion: 'v1/version/create-version',
      versions: 'v1/version/versions',
    },
    dashboard: {
      productDataAnalysis: 'v1/dashboard/product-data-analysis',
      saleDataAnalysis: 'v1/dashboard/sale-data-analysis',
      returnDataAnalysis: 'v1/dashboard/return-data-analysis',
    },
    products: {
      productList: 'v1/products/search',
      create: 'v1/products/create-product',
      productByProductId: 'v1/products/by-product-id',
      categoryAttributesByCategoryId:
        'v1/products/category-attributes-by-category-id',
      createNewAdvertControl: 'v1/products/create-new-advert-control',
      infoForTrendyolNewAdvertByProdoctId:
        'v1/products/info-for-trendyol-new-advert-by-product-id',
      currentInfoFromSiteByProductId:
        'v1/products/current-info-from-site-by-product-id',
      allCurrentInfoFromSite: 'v1/products/all-current-info-from-site',
      infoForHepsiBuradaNewAdvertByProdoctId:
        'v1/hepsiburadaproducts/info-for-hepsiburada-new-advert-by-product-id',
      allSearch: 'v1/products/all-search',
      productByBarcodeOrStockCode: 'v1/products/by-barcode-or-stock-code',
      updateProductStock: 'v1/products/update-product-stock',
    },
    autoPricing: {
      search: 'v1/autopricings/search',
      trendyolOtherSellers: 'v1/trendyolautopricings/other-sellers',
      createTrendyolAutoPricing: 'v1/trendyolautopricings/create-auto-pricing',
      updateTrendyolAutoPricing: 'v1/trendyolautopricings/update',
      trendyolAutoPricingLogs: 'v1/trendyolautopricings/logs',
      trendyolAutoPricingChangeActived:
        'v1/trendyolautopricings/change-actived',
      trendyolDeail: 'v1/trendyolautopricings/detail',
      trendyolAutoPricingLogSearch: 'v1/trendyolautopricings/search-logs',
      createAutoPricing: 'v1/autopricings/create-auto-pricing',
      createAutoPricingPreview: 'v1/autopricings/create-auto-pricing-preview',
    },
    company: {
      createOrUpdateCompanyInfo: 'v1/companies/create-or-update',
      info: 'v1/companies/info',
    },
    trendyolOrders: {
      newOrder: 'v1/trendyolorders/new-orders',
      detail: 'v1/trendyolorders/detail',
      updatePackagesPicking: 'v1/trendyolorders/update-packages-picking',
      packedChangeCargoCompany: 'v1/trendyolorders/packed-change-cargo-company',
    },
    sales: {
      search: 'v1/orders/search',
      exportCargoBarcode: 'v1/orders/export-cargo-barcode',
      packagesPreview: 'v1/orders/packages-preview',
      packages: 'v1/orders/packages',
      packagesSplit: 'v1/orders/packages-split',
      packagesSplitPreview: 'v1/orders/packages-split-preview',
    },
    retailSale: {
      search: 'v1/retailsales/search',
      createRetailSale: 'v1/retailsales/create',
      lines: 'v1/retailsales/lines',
    },
    trendyolReturns: {
      returnOrderLines: 'v1/trendyolreturns/order-lines',
      returnClaimItems: 'v1/trendyolreturns/claim-items',
      newReturn: 'v1/trendyolreturns/new-return',
    },
    returns: {
      search: 'v1/returns/search',
    },
    categories: {
      forCreateProduct: 'v1/categories/for-create-product',
      tree: 'v1/categories/tree',
      create: 'v1/categories/create',
      update: 'v1/categories/update',
      delete: 'v1/categories/delete',
    },
    brands: {
      search: 'v1/brands/search',
      create: 'v1/brands/create',
      update: 'v1/brands/update',
      delete: 'v1/brands/delete',
      all: 'v1/brands/all',
    },
    tags: {
      search: 'v1/tags/search',
      create: 'v1/tags/create',
      update: 'v1/tags/update',
      delete: 'v1/tags/delete',
      all: 'v1/tags/all',
    },
    sendMail: {
      sendInfoMail: 'v1/sendemails/send-info-mail',
    },
    integration: {
      createTrendyolStore: 'v1/trendyol/create-account',
      stores: 'v1/stores',
      storeImportInfo: 'v1/stores/store-import-info',
      storesByCustomerId: 'v1/stores/by-customer-id',
      trendyolStoreDetail: 'v1/trendyolstores/detail-by-store-id',
      trendyolCreateOrUpdateAddresses:
        'v1/trendyolstores/create-or-update-addresses',
      trendyolStoreAddresses: 'v1/trendyolstores/addresses',
      trendyolShipmentProviders: 'v1/trendyolstores/shipment-providers',
      trendyolUpdateStore: 'v1/trendyolstores/update-store',
      trendyolStoreInfo: 'v1/trendyolstores/info',
      createHepsiBuradaStore: 'v1/hepsiburadastores/create-account',
      createN11Store: 'v1/nonbirstores/create-account',
      hepsiBuradaShipmentProviders: 'v1/hepsiburadastores/shipment-providers',
      hepsiBuradaStoreDetail: 'v1/hepsiburadastores/detail-by-store-id',
      hepsiBuradaUpdateStore: 'v1/hepsiburadastores/update-store',
      n11StoreDetail: 'v1/nonbirstores/detail-by-store-id',
      n11UpdateStore: 'v1/nonbirstores/update-store',
    },
    valueTable: {
      vats: 'v1/valuetables/vats',
      foreignCurrencies: 'v1/valuetables/foreign-currencies',
      currencyCodes: 'v1/valuetables/currency-codes',
      unitTypes: 'v1/valuetables/unit-types',
      countries: 'v1/valuetables/countries',
    },
    productStockTransactions: {
      search: 'v1/productstocktransactions/search',
      byProductId: 'v1/productstocktransactions/by-product-id',
    },
    questions: {
      search: 'v1/questions/search',
    },
    trendyolQuestion: {
      newQuestions: 'v1/trendyolquestions/new-questions',
      answer: 'v1/trendyolquestions/answer',
      trendyolByStoreId: 'v1/trendyolquestions/trendyol-by-store-id',
    },
    batchoperations: {
      storeProductsViewForTrendyol: 'v1/trendyol/store-products-view',
      storeImportForTrendyol: 'v1/trendyol/store-import',
      deleteAllProductAndRelation:
        'v1/products/delete-all-product-and-relation',
      postProductBatchsSearch: 'v1/productbatchs/search',
      currentStatus: 'v1/productbatchs/current-status',
      updatePrice: 'v1/batchs/update-price',
      updatePricePreview: 'v1/batchs/update-price-preview',
      updateVat: 'v1/batchs/update-vat',
      updateVatPreview: 'v1/batchs/update-vat-preview',
    },
    varianters: {
      search: 'v1/varianters/search',
      create: 'v1/varianters/create',
      update: 'v1/varianters/update',
      delete: 'v1/varianters/delete',
      all: 'v1/varianters/all',
      searchVarianterValues: 'v1/varianters/search-varianter-values',
      createVarianterValue: 'v1/varianters/create-varianter-value',
      updateVarianterValue: 'v1/varianters/update-varianter-value',
      deleteVarianterValue: 'v1/varianters/delete-varianter-value',
    },
    users: {
      search: 'users/search',
      info: 'users/info',
      getAll: 'users',
      superAdminCreateUser: 'users/superadmin-create-user',
      userPermissions: 'users/permissions',
      changePassword: 'users/change-password',
    },
    roles: {
      search: 'roles/search',
      permisisons: 'roles/permissions',
      createRolePermission: 'roles/create-permission',
    },
    customers: {
      search: 'v1/customers/search',
      customerCustomers: 'v1/customers/customer-customers',
      customerCustomerAllSearch: 'v1/customers/customer-customers-all-search',
      customerCustomerSearch: 'v1/customers/customer-customers-search',
      createCustomerCustomer: 'v1/customers/create-customer-customers',
    },
    trendyol: {
      forCreateProductCategories: 'v1/trendyol/for-create-product-categories',
      searchBrands: 'v1/trendyol/search-brands',
      categoryAttributes: 'v1/trendyol/category-attributes',
      allTrendyolCategoryInfo: 'v1/trendyol/all-trendyol-category-info',
      createProductCategoryAttributes:
        'v1/trendyol/create-product-category-attributes',
    },
    hepsiburada: {
      forCreateProductCategories:
        'v1/hepsiburada/for-create-product-categories',
      searchBrands: 'v1/hepsiburada/search-brands',
      categoryAttributes: 'v1/hepsiburada/category-attributes',
    },
    hepsiBuradaOrders: {
      newOrder: 'v1/hepsiburadaorders/new-orders',
      detail: 'v1/hepsiburadaorders/detail',
      packedChangeableCargoCompanies:
        'v1/hepsiburadaorders/packed-changeable-cargo-companies',
      packedChangeCargoCompany:
        'v1/hepsiburadaorders/packed-change-cargo-company',
    },
    hepsiBuradaQuetion: {
      newQuestions: 'v1/hepsiburadaquestions/new-questions',
      conversationsById: 'v1/hepsiburadaquestions/conversations-by-id',
      answer: 'v1/hepsiburadaquestions/answer',
    },
    hepsiBuradaReturns: {
      newReturn: 'v1/hepsiburadareturns/new-return',
    },
    n11Returns: {
      newReturn: 'v1/nonbirreturns/new-return',
    },
    n11Orders: {
      newOrder: 'v1/nonbirorders/new-orders',
    },
    n11Products: {
      productMatchByBarcodePreview:
        'v1/nonbirproducts/product-match-by-barcode-preview',
        productMatchByBarcode:
        'v1/nonbirproducts/product-match-by-barcode',
    },
    customerTrendyol: {
      createProduct: 'v1/trendyolproduct/create-product',
      byProductId: 'v1/trendyolproduct/by-product-id',
      updateProduct: 'v1/trendyolproduct/update-product',
    },
    customerHepsiBurada: {
      createProduct: 'v1/hepsiburadaproducts/create-product',
      fastCreateProductPreview:
        'v1/hepsiburadaproducts/fast-create-product-preview',
      fastCreateProduct: 'v1/hepsiburadaproducts/fast-create-product',
      hepsiBuradaStoreProductMatchPreview:
        'v1/hepsiburadaproducts/store-product-match-preview',
      hepsiBuradaStoreProductMatchPreviewV2:
        'v1/hepsiburadaproducts/store-product-match-preview-v2',
      hepsiBuradaStoreProductMatch:
        'v1/hepsiburadaproducts/store-product-match',
      hepsiBuradaStoreProductMatchV2:
        'v1/hepsiburadaproducts/store-product-match-v2',
      otherSellers: 'v1/hepsiburadaproducts/other-sellers',
      removeProductRelation: 'v1/hepsiburadaproducts/remove-product-relation',
    },
    hepsiBuradaProductBatch: {
      currentStatus: 'v1/hepsiburadaproductbatchs/current-status',
    },
    invoice: {
      invoiceSearch: 'v1/invoices/search',
      myInvoiceAccounts: 'v1/invoices/my-invoice-accounts',
      createPaymentInvoiceAccountHistory:
        'v1/invoices/create-payment-account-history',
      searchPaymentInvoiceAccountHistory:
        'v1/invoices/search-payment-account-history',
      gibCheckTaxNumber: 'v1/invoices/GIB-check-taxnumber',
      invoiceSerieSearch: 'v1/invoices/invoice-serie-search',
      invoiceTemplateSearch: 'v1/invoices/invoice-template-search',
      createInvoiceSerie: 'v1/invoices/create-invoice-serie',
      createInvoiceTemplate: 'v1/invoices/create-invoice-template',
      serieSetActiveOrPassive: 'v1/invoices/serie-set-active-or-passive',
      templateSetActiveOrPassive: 'v1/invoices/template-set-active-or-passive',
      serieSetIsDefault: 'v1/invoices/serie-set-is-default',
      templateSetIsDefault: 'v1/invoices/template-set-is-default',
      allSerie: 'v1/invoices/all-serie',
      allTemplate: 'v1/invoices/all-template',
    },
    trendyolInvoiceSovos: {
      createAccount: 'v1/trendyolinvoicesovos/create-account',
      updateAccount: 'v1/trendyolinvoicesovos/update-account',
      accountDetail: 'v1/trendyolinvoicesovos/account-detail',
      checkCustomerTaxId: 'v1/trendyolinvoicesovos/check-customer-tax-id',
      createInvoice: 'v1/trendyolinvoicesovos/create-invoice',
      cancelInvoice: 'v1/trendyolinvoicesovos/cancel-invoice',
      updateInvoiceSite: 'v1/trendyolinvoicesovos/update-invoice-site',
    },
    qnbInvoice: {
      createAccount: 'v1/qnbinvoices/create-account',
      updateAccount: 'v1/qnbinvoices/update-account',
      accountDetail: 'v1/qnbinvoices/account-detail',
      createInvoice: 'v1/qnbinvoices/create-invoice',
      checkCustomerTaxId: 'v1/qnbinvoices/check-customer-tax-id',
      cancelInvoice: 'v1/qnbinvoices/cancel-invoice',
    },
    edmInvoice: {
      createAccount: 'v1/edminvoices/create-account',
      updateAccount: 'v1/edminvoices/update-account',
      accountDetail: 'v1/edminvoices/account-detail',
      createInvoice: 'v1/edminvoices/create-invoice',
      createInvoicePreview: 'v1/edminvoices/create-invoice-preview',
      checkCustomerTaxId: 'v1/edminvoices/check-customer-tax-id',
      cancelInvoice: 'v1/edminvoices/cancel-invoice',
    },
    nilveraInvoice: {
      allGetFromApi: 'v1/nilverainvoices/get-from-api',
      accountDetail: 'v1/nilverainvoices/account-detail',
      createAccount: 'v1/nilverainvoices/create-account',
      searchSeries: 'v1/nilverainvoiceseries/search',
      getSeries: 'v1/nilverainvoiceseries',
      allSeries: 'v1/nilverainvoiceseries/all',
      serieDetail: 'v1/nilverainvoiceseries/detail',
      createInvoiceSerie: 'v1/nilverainvoiceseries/create',
      searchTemplates: 'v1/nilverainvoicetemplates/search',
      getInvoiceTemplates: 'v1/nilverainvoicetemplates',
      allTemplates: 'v1/nilverainvoicetemplates/all',
      createInvoiceTemplate: 'v1/nilverainvoicetemplates/create',
      checkTaxNumber: 'v1/nilverainvoices/check-taxnumber',
      createPreviewInvoice: 'v1/nilverainvoices/create-preview',
      downloadPdf: 'v1/nilverainvoices/download-pdf',
    },
    harInvoice: {
      accountDetail: 'v1/harinvoices/account-detail',
    },
  },
};
