export const getExtensionFromFileName = (fileName: string): string => {
  return '.' + fileName.split('?')[0].split('.').pop();
};

export const stringToDouble = (value?: string): number | null => {
  if (value == null || value == '') return null;

  value = value.replace(',', '.');
  return parseFloat(value);
};

export const doubleToString = (value?: number): string | null => {
  if (value == null || value == undefined) return null;

  return value.toString().replace('.', ',');
};

export const stringToString = (value?: string): string | null => {
  if (value == null || value == '') return null;

  return value;
};

export const intToString = (value?: number): string | null => {
  if (value == null) return null;

  return value.toString();
};

export const stringToInt = (value?: string): number | null => {
  if (value == null || value == '') return null;

  return parseInt(value);
};

export const ngbDateFormatToString = (value?: any): string | null => {
  if (value == null || value == '') return null;

  return value.year + '-' + value.month + '-' + value.day;
};

export const dateFullToString = (value?: Date): string | null => {
  if (value == null || value == undefined) return null;

  var hour = value.getHours();
  var minute = value.getMinutes();
  var seconds = value.getSeconds();
  var miliseconds = value.getMilliseconds();

  return (
    value.getFullYear() +
    '-' +
    padLeftString((value.getMonth() + 1).toString()) +
    '-' +
    padLeftString(value.getDate().toString()) +
    'T' +
    padLeftString(hour.toString()) +
    ':' +
    padLeftString(minute.toString()) +
    ':' +
    padLeftString(seconds.toString()) +
    '.' +
    miliseconds +
    'Z'
  );
};

export const dateToString = (value?: Date): string | null => {
  if (value == null || value == undefined) return null;

  return (
    value.getFullYear() +
    '-' +
    padLeftString((value.getMonth() + 1).toString()) +
    '-' +
    padLeftString(value.getDate().toString())
  );
};

export const dateStringToFormatString = (value?: string): string | null => {
  if (value == null || value == undefined) return null;

  var date = new Date(value);

  return (
    date.getFullYear() +
    '-' +
    padLeftString((date.getMonth() + 1).toString()) +
    '-' +
    padLeftString(date.getDate().toString())
  );
};

export const padLeftString = (value: string): string => {
  if (value.length == 1) {
    return '0' + value;
  }

  return value;
};

export const countWords = (value: string): number => {
  if (value == null) {
    return 0;
  }
  const words = value.trim().split(/\s+/);
  return words.length;
};

export const roundNumber = (number: number, decimalPlaces: number): number => {
  const roundedNumber =
    Math.round(number * Math.pow(10, decimalPlaces)) /
    Math.pow(10, decimalPlaces);
  const roundNumber = roundedNumber.toFixed(decimalPlaces);

  return stringToDouble(roundNumber);
};
