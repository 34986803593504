export const CkEditorUtils = {
  toolbar: {
    items: [
      'heading',
      '|',
      'alignment',
      '|',
      'bold',
      'italic',
      'strikethrough',
      'underline',
      '|',
      'link',
      '|',
      'bulletedList',
      'numberedList',
      'todoList',
      '-',
      'fontfamily',
      'fontsize',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'uploadImage',
      'undo',
      'redo',
    ],
    shouldNotGroupWhenFull: true,
  },
  language: 'tr',
  licenseKey: '',
};
