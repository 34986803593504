export const ROUTER_UTILS = {
  config: {
    site: {
      root: '',
      siteBase: ''
    },
    auth: {
      root: 'yetki',
      signIn: 'giris-yap',
      trialCreation: 'deneme-surumu-olustur',
      signUp: 'kayit-ol',
      forgotPassword: 'sifremi-unuttum',
      forgotPasswordEmailSent: 'sifremi-unuttum-email-gonder',
      passwordReset: 'sifre-sifirla',
      passwordResetFailed: 'hatali-sifre-sifirla',
      passwordResetSucceeded: 'basarili-sifre-sifirla',
      confirmEmail: 'email-onayla',
    },
    firm: {
      root: 'firma',
      firmInfo: 'firma-bilgileri',
    },
    home: {
      root: 'gosterge-paneli',
      versions: 'duyurular',
      createVersion: 'yeni',
    },
    dashboard: {
      root: '',
      productDataAnalysis: 'urun-analizi',
      saleDataAnalysis: 'siparis-analizi',
      returnDataAnalysis: 'iade-analizi',
    },
    products: {
      root: 'urunler',
      productList: '',
      createProduct: 'yeni',
      createTrendyolProduct: 'trendyol-yeni',
      updateTrendyolProduct: 'trendyol-duzenle',
      createHepsiBuradaProduct: 'hepsiburada-yeni',
      updateHepsiBuradaProduct: 'hepsiburada-duzenle',
    },
    sales: {
      root: 'siparisler',
      saleList: 'siparisler-listesi',
    },
    returns: {
      root: 'iadeler',
      returnList: 'iadeler-listesi',
    },
    autoPricing: {
      root: 'otomatik-fiyatlandirma',
      autoPricingList: 'listesi',
      createAutoPricing: 'yeni',
    },
    integrations: {
      root: 'entegrayonlar',
      myStores: 'magazalarim',
      trendyolMyStoreDetail: 'trendyol-magaza/detay',
      createTrendyolStore: 'trendyol-magaza-ekle',
      createHepsiBuradaStore: 'hepsiburada-magaza-ekle',
      createN11Store: 'n11-magaza-ekle',
      hepsiBuradaMyStoreDetail: 'hepsiburada-magaza/detay',
      n11MyStoreDetail: 'n11-magaza/detay',
      myEInvoice: 'fatura-entegrasyonlarim',
      harCreateEInvoice: 'har-fatura-entegrasyonu-ekle',
      createNilveraEInvoice: 'nilvera-entegrasyonu-ekle',
      createTrendyolInvoiceSovos: 'trendyol-fatura-sovos-entegrasyonu-ekle',
      createQNBInvoice: 'qnb-fatura-entegrasyonu-ekle',
      createEDMInvoice: 'edm-fatura-entegrasyonu-ekle',
      harInvoiceDetail: 'har-fatura-entegrasyonu-detayi',
      nilveraInvoiceDetail: 'nilvera-fatura-entegrasyonu-detayi',
      trendyolInvoiceSovosDetail: 'trednyol-fatura-sovos-entegrasyonu-detayi',
      qnbInvoiceDetail: 'qnb-fatura-entegrasyonu-detayi',
      edmInvoiceDetail: 'edm-fatura-entegrasyonu-detayi',
    },
    accounting: {
      root: 'muhasebe-islemleri',
      invoice: 'faturalarim',
      createNilveraInvoice: 'nilvera-fatura-ekle',
      createTrendyolSovosInvoice: 'trendyol-sovos-fatura-ekle',
      createQNBInvoice: 'qnb-fatura-ekle',
      createEDMInvoice: 'edm-fatura-ekle',
      myCustomer: 'musterilerim',
      invoiceSerie: 'fatura-serileri',
      invoiceTemplate: 'fatura-sablonlari',
    },
    batchOperations: {
      root: 'topluislemler',
      storeImport: 'magaza-ice-aktar',
      productBatch: 'urun-islem-durumlari',
      updatePrice: 'fiyat-guncelle',
      updateVat: 'kdv-guncelle',
      deleteProdutcsAndAllRelation: 'urun-ve-iliskilerinin-hepsini-sil',
      hepsiBuradaProductMatch: 'hepsi-burada-urun-esleme',
    },
    values: {
      root: 'tanimlamalar',
      categories: 'kategoriler',
      brands: 'markalar',
      varianters: 'varyantlar',
      varianterValues: 'varyantdegerleri',
      tags: 'etiketler',
    },
    tracking: {
      root: 'takipler',
      stockTransactions: 'stok-hareketleri',
      questions: 'sorular',
    },
    settings: {
      root: 'ayarlar',
      notifications: 'bildirim',
    },
    contracts: {
      root: 'sozlesmeler',
      userAgreement: 'kullanici-sozlesmesi',
    },
    user: {
      root: 'kullanıcı',
      profile: 'profil',
    },
    retailSale: {
      root: 'perakende-satis',
      retailSales: '',
      createRetailSale: 'yeni',
    },
  },
};
