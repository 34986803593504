import { HttpErrorResponse } from '@angular/common/http';

export const httpErrorResponseUtil = (error: HttpErrorResponse): string => {
  let errorStr = '';
  if (error.status == 405) {
    errorStr = JSON.stringify(
      'Bu method tipine izin verilmemekte ya da bulunmamaktadır.'
    );
  } else if (error.error.errors != undefined) {
    errorStr = JSON.stringify(error.error.errors);
  } else if (error.error.messages != null) {
    errorStr = JSON.stringify(error.error.messages);
  } else {
    if (error.error.message != undefined) {
      errorStr = JSON.stringify(error.error.message);
    } else {
      errorStr = JSON.stringify(error.message);
    }
  }
  return errorStr;
};
